<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Login Report"
      slot="title"
      link="/helpContent/loginReport"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="filtering.staffId"
          />
        </div>
        <div class="col-sm-2">
          <InputDate label="Date" v-model="filtering.date" />
        </div>
        <div class="col-sm-3">
          <FormButton
            style="margin-top: 5px"
            type="primary"
            @click="search"
            :rounded="true"
          >
            <Icon type="ios-search" />
          </FormButton>
        </div>
      </FormRow>
    </ValidationObserver>

    <h4 style="margin-bottom: 5px;">{{ message }}</h4>
    <FormRow>
      <div class="col-sm-12">
        <DataTable :actions="true"
            :data="reportData"
            :columns="table"
            :is-loading="isLoading"
 
          >
          <!-- @on-selection-change="selectChange"
      @on-page-change="pageChange"
      :pagination="pagination"
      @on-sort-change="sortChange" -->
        </DataTable>
      </div>
    </FormRow>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from 'Components/DataTable'
import loadingMixin from "Mixins/loadingMixin";
import InputDate from "Components/form/InputDate";
import { getAllStaffByHRorManager, getLoginUserReport } from "../api";
import table from "./table";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    ValidationObserver,
    InputDate
  },
  data() {
    return {
      filtering: {
        staffId: null,
        date:'',
      },
      staffList: [],
      sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
      table,
      reportData: [],
      selectedRows: [],
      message: "",
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getStaff();
  },
  methods: {
    async getStaff() {
      await getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
      });

      this.getDataByFilter();
    },
    search() {
      this.getDataByFilter();
    },

    getDataByFilter() {
      setTimeout(() => {
 
            // this.showLoader();
            const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;

              const filters = {};
              filters.staffId = this.filtering.staffId;
              filters.date = this.filtering.date;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pageSize: perPage,
                    },
                  ],
                },
              };
            getLoginUserReport(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          
        },50);
      
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
    handleResponse(response) {
      this.hideLoader();
      this.filtering.staffId=null;
      this.filtering.date='';
      this.reportData = response.data;
      this.message = response.data[0].Message;
    },
  },
};
</script>

