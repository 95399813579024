export default [
    {
        title: 'Staff Name               ',
        key: 'StaffName',
        minWidth: 40,
    },
    {
        title: 'Last Login Date      ',
        key: 'LastLoginDate',
        minWidth: 40,
    },
    {
        title: 'Time',
        key: 'Time',
        minWidth: 80,
    },

	{
        title: 'IP address',
        key: 'IPAddress',
        minWidth: 40,
    },


]
